import React from "react"
import { Link } from "gatsby"
import * as actions from "../../redux/actions"
import { connect } from "react-redux"
import Styled from "styled-components"

import { ROUTES, DEVICES, URLS } from "../../config"
import ExternalLink from "../ExternalLink"
import { Facebook, Instagram } from "../../components/Common/fontawesome"

const Footer = Styled.footer`
    color: ${({ theme }) => theme.primaryLight};
    font-size: 1rem;
    background-color: ${({ theme }) => theme.primaryDark};
    position: relative;
    padding-top: 20px;
    margin-top: 100px;
    bottom: 0;
`
const FooterLeft = Styled.div`
    display: flex;
    justify-content: flex-start !important;
    ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            color: ${({ theme }) => theme.primaryLight};
            margin-right: 10px;
        }
        li:last-child {
          margin-right: 0px;
        }
    }
    @media all and ${DEVICES.maxLaptop} {
        justify-content: center !important;
    }
`
const FooterRight = Styled.div`
    display: flex;
    justify-content: flex-end !important;
    ul {
        padding-left: 0px;
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        li {
            a {
                margin-left: 10px;
                color: ${({ theme }) => theme.primaryLight};
                &:hover {
                  text-decoration: none;
                  color: ${({ theme }) => theme.secondary};
                }
            }
        }
    }
    @media all and ${DEVICES.maxLaptop} {
        justify-content: center;
    }
    @media all and ${DEVICES.maxMobileL} {
        ul {
            flex-direction: column;
            width: 100%;
            text-align: center;
            li {
                padding-top: 10px;
                width: 100%;
                border-top: 1px solid #ffffff;
            }
        }
    }
`

const FooterBottom = Styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #ffffff;
    padding: 10px;
    color: rgba(255,255,255,0.5);
`

const MyFooter = ({ display, authenticated }) => {
  let className = "container-fluid mr-0 ml-0"
  if (!display) {
    className += "d-none"
  }
  console.log("authenticated", authenticated)
  return (
    <Footer className={className}>
      <div className="row">
        <FooterLeft className="col-lg-4">
          <ul>
            <li>
              <ExternalLink href={URLS.INSTAGRAM}>
                <Instagram className="svg-social-footer" />
              </ExternalLink>
            </li>
            <li>
              <ExternalLink href={URLS.FACEBOOK}>
                <Facebook className="svg-social-footer" />
              </ExternalLink>
            </li>
          </ul>
        </FooterLeft>
        <FooterRight className="col-lg-8">
          <ul>
            {ROUTES.map(route => {
              if (route.link) {
                return (
                  <li key={`${route.name}-menu`} className="bebas">
                    <ExternalLink href={`${route.link}`}>
                      {route.name}
                    </ExternalLink>
                  </li>
                )
              }
              return (
                <li key={`${route.name}-menu`} className="bebas">
                  <Link to={`${route.path}`}>{route.name}</Link>
                </li>
              )
            })}
          </ul>
        </FooterRight>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FooterBottom>
            <span>&copy; {new Date().getFullYear()} Body By Kickbush</span>
          </FooterBottom>
        </div>
      </div>
    </Footer>
  )
}

const MapStateToProps = state => {
  return { authenticated: state.auth.authenticated }
}

export default connect(
  MapStateToProps,
  actions
)(MyFooter)
