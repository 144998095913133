import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import ExternalLink from "../../ExternalLink"
import { ROUTES } from "../../../config"

const Menu = Styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  padding: 2rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`
const MenuLogo = Styled(Img)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 120px;
`
const MenuItems = Styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  li {
    list-style: none;
    align-self: center;
    a {
      text-decoration: none;
      text-transform: uppercase;
      height: 100%;
      color: ${({ theme }) => theme.primaryDark};
      font-size: 2rem;
      font-weight: 500;
      transition: color 0.3s linear;
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: ${({ theme }) => theme.mobile}) {
        font-size: 1.5rem;
        text-align: center;
      }
    }
  }
`
const MyMenu = ({ open }) => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      logoBlack: allFile(filter: { name: { eq: "bbk_icon_black_500x500" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const logoBlack = data.logoBlack.edges[0].node.childImageSharp.fluid
  return (
    <Menu open={open}>
      <MenuItems>
        <li>
          <Link to="/">
            <MenuLogo
              fluid={logoBlack}
              alt="BBK"
              imgStyle={{ objectFit: "contain" }}
            />
          </Link>
        </li>
        {ROUTES.map(route => {
          if (route.link) {
            return (
              <li key={`${route.name}-menu`} className="bebas">
                <ExternalLink href={`${route.link}`}>{route.name}</ExternalLink>
              </li>
            )
          }
          return (
            <li key={`${route.name}-menu`} className="bebas">
              <Link to={`${route.path}`}>{route.name}</Link>
            </li>
          )
        })}
      </MenuItems>
    </Menu>
  )
}

export default MyMenu
